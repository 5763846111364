// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.94.0_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.94.0_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.deactivate-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.deactivate-container .form-wrap {
  margin: 24px;
  width: 100%;
  max-width: 1024px;
}
.deactivate-container .form-wrap .title {
  font-size: 3rem;
  margin-bottom: 1rem;
  font-weight: 700;
}
.deactivate-container .form-wrap .tips {
  line-height: 1.625;
  font-size: 1.125rem;
  color: hsl(0, 0%, 45.1%);
}
`, "",{"version":3,"sources":["webpack://./src/view/Deactivate/deactivate.less"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,aAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AACF;AANA;EAQI,YAAA;EACA,WAAA;EACA,iBAAA;AACJ;AAXA;EAaM,eAAA;EACA,mBAAA;EACA,gBAAA;AACN;AAhBA;EAmBM,kBAAA;EACA,mBAAA;EACA,wBAAA;AAAN","sourcesContent":[".deactivate-container {\n  width: 100vw;\n  height: 100vh;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  .form-wrap {\n    margin: 24px;\n    width: 100%;\n    max-width: 1024px;\n\n    .title {\n      font-size: 3rem;\n      margin-bottom: 1rem;\n      font-weight: 700;\n    }\n\n    .tips {\n      line-height:1.625;\n      font-size: 1.125rem;\n      color: hsl(0 0% 45.1%);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
