import React from 'react'
import { OverPack } from 'rc-scroll-anim'
import QueueAnim from 'rc-queue-anim'
import { Button } from 'antd'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

function Download() {
    const { t } = useTranslation()

    return (
        <div className="home-page page2">
            <div className="home-page-wrapper">
                <div className="title-line-wrapper page2-line">
                    <div className="title-line"/>
                </div>
                <h2>{ t( 'download.title' ) }<span> { t( 'name' ) }</span></h2>
                <OverPack playScale={ [ 0.1, 0.1 ] }>
                    <QueueAnim key="queue" type="bottom" leaveReverse>
                        <div key="button" style={ { marginTop: 20 } }>
                            <Button
                                style={ { background: 'linear-gradient(135deg, #18BAFF, #1867FF)' } }
                                type="primary">
                                <Link to="/share">{ t( 'share.download' ) }</Link>
                            </Button>
                        </div>
                    </QueueAnim>
                </OverPack>
            </div>
        </div>
    )
}

export default Download
