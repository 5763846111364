import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import to from 'await-to-js'
import fingerprint from '../../utils/fingerprint.js'
import { appVersion, saveTempVisitorInfo } from '../../api/share'

//
export const useFacility = () => {

    // 生成 设备id 和 设备信息
    async function createHashIdent() {

        // 生成Hash标识
        let mark = ''
        // 设备信息
        let visitorData = {}

        const height = window.screen.height * window.devicePixelRatio
        const width = window.screen.width * window.devicePixelRatio

        try {
            const f = await fingerprint.load()
            const result = await f.get()

            const { platform, colorGamut, languages, fonts, colorDepth } = result.components
            if ( platform && 'value' in platform ) {
                visitorData.platform = platform
            }
            visitorData.screen = {
                value: [ width, height ]
            }
            if ( colorGamut && 'value' in colorGamut ) {
                visitorData.colorGamut = colorGamut
            }
            if ( languages && 'value' in languages ) {
                const langs = languages.value.flat()
                visitorData.languages = {
                    value: Array.from( new Set( langs ) ),
                }

            }
            if ( fonts && 'value' in fonts ) {
                visitorData.fonts = fonts
                visitorData.fonts.duration = 0
            }
            if ( colorDepth && 'value' in colorDepth ) {
                visitorData.colorDepth = colorDepth
            }
            mark = fingerprint.hashComponents( structuredClone( visitorData ) )
            if ( window.android ) {
                window.android.callApp( mark )
            }
            if ( window.webkit ) {
                window.webkit.messageHandlers.callApp.postMessage( mark )
            }
        } catch ( err ) {
            console.error( err )
        }

        return { visitorId: mark, visitorInfo: visitorData }
    }

    // 获取 系统 os
    function getFacilityOS() {
        // 系统信息
        const userAgent = navigator.userAgent || navigator.vendor || globalThis.opera
        // 检测 iOS
        if ( /iPad|iPhone|iPod/.test( userAgent ) && !globalThis.MSStream ) {
            return 'iOS'
        }

        // 检测 Android
        if ( /android/i.test( userAgent ) ) {
            return 'Android'
        }

        // 检测 Windows
        if ( /Win/i.test( userAgent ) ) {
            return 'Windows'
        }

        // 检测 macOS
        if ( /Mac/i.test( userAgent ) ) {
            return 'macOS'
        }

        // 检测 Linux
        if ( /Linux/i.test( userAgent ) ) {
            return 'Linux'
        }

        // 如果未检测到，返回 undefined
        return undefined

    }


    return { createHashIdent, getFacilityOS }
}


export const useShare = () => {
    const { createHashIdent, getFacilityOS } = useFacility()
    const location = useLocation()

    const [ appData, setAppData ] = useState( {} )
    const [ loading, setLoading ] = useState( false )

    // 获取app版本信息
    async function sendAppVersion() {
        setLoading(true)
        const osArr = [ undefined, 'iOS', 'Android', 'macOS', 'Windows', 'Linux' ]
        const OS = getFacilityOS()
        const type = osArr.indexOf( OS )
        if ( !type ) return setLoading(false)
        const [ err, res ] = await to( appVersion( { type } ) )
        setLoading(false)
        if ( err ) return
        const { data } = res
        setAppData( data )
    }

    // 保存临时访客信息
    async function sendSaveTempVisitorInfo() {
        const queryParams = new URLSearchParams( location.search )
        const code = queryParams.get( 'code' )
        if ( !code ) return
        const { visitorId, visitorInfo } = await createHashIdent()

        const params = {
            "visitor_info": JSON.stringify( visitorInfo ),
            "visitor_id": visitorId,
            "affiliate_code": code
        }

        const [ err, res ] = await to( saveTempVisitorInfo( params ) )
        if ( err ) return console.error( err )
        console.log( 'sendSaveTempVisitorInfo', res.data )
    }

    useEffect( () => {
        sendAppVersion().then()
        sendSaveTempVisitorInfo().then()
    }, [] )

    return { appData,loading }
}
