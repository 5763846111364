import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import QueueAnim from 'rc-queue-anim'
import TweenOne from 'rc-tween-one'
import { Button } from 'antd'
import { useTranslation } from 'react-i18next'
import bannerPng from 'images/banner.png'

function Banner( props ) {
    const { t } = useTranslation()

    return (
        <div className="banner-wrapper">
            { props.isMobile && (
                <TweenOne animation={ { opacity: 1 } } className="banner-image-wrapper">
                    <div className="home-banner-image">
                        <img alt="banner" src={ bannerPng } width="100%"/>
                    </div>
                </TweenOne>
            ) }
            <QueueAnim className="banner-title-wrapper" type={ props.isMobile ? 'bottom' : 'right' }>
                <div key="line" className="title-line-wrapper">
                    <div
                        className="title-line"
                        style={ { transform: 'translateX(-64px)' } }
                    />
                </div>
                <h1 key="h1">{ t( 'banner.title' ) }</h1>
                <p key="content">{ t( 'banner.description' ) }</p>
                <div key="button" className="button-wrapper">
                    <Button
                        style={ { background: 'linear-gradient(135deg, #18BAFF, #1867FF)' } }
                        type="primary">
                        <Link to="/share">{ t( 'share.download' ) }</Link>
                    </Button>
                    <a className="external-links" href="https://play.google.com/store/apps/details?id=com.jiashu.android" target="_blank">
                        <svg t="1734079825666" className="icon" viewBox="0 0 1024 1024" version="1.1"
                             xmlns="http://www.w3.org/2000/svg" p-id="3200" width="38" height="38">
                            <path
                                d="M607.0016 523.7504L173.6704 81.4976h-1.088a69.1456 69.1456 0 0 0-37.8368 62.4v761.0112a68.7104 68.7104 0 0 0 37.184 62.4l1.088-1.088 433.984-440.7296s0.6656-1.3056 0-1.7408z"
                                fill="#10CFFF" p-id="3201"></path>
                            <path
                                d="M642.4448 488.96l102.6304-103.0656v-2.3808L270.4256 104.5376a1.3184 1.3184 0 0 0-0.448 0.9856c0 0.3712 0.1664 0.7296 0.448 0.9728L641.152 488.32s0.6656 0.64 1.3056 0.64z"
                                fill="#0FF07E" p-id="3202"></path>
                            <path
                                d="M891.84 470.6944l-99.584-58.9184h-1.3056l-110.6688 111.5392a1.7408 1.7408 0 0 0 0 2.3936l110.6688 111.3216h1.3056a1.9712 1.9712 0 0 1 0-1.3056l101.9776-58.9312a65.2288 65.2288 0 0 0 21.7472-53.696 66.304 66.304 0 0 0-24.1408-52.4032z"
                                fill="#FFCA0A" p-id="3203"></path>
                            <path
                                d="M643.5328 561.3568a2.8288 2.8288 0 0 0-1.3056 0L268.6848 943.616v1.7408c0 0.6528 0 0 0 0L745.088 668.5568v-1.088c0-1.088 0 0 0 0l-101.5424-106.112z"
                                fill="#FA3E4D" p-id="3204"></path>
                        </svg>
                    </a>
                    <a className="external-links" href="https://apps.apple.com/at/app/%E5%AE%B6%E4%B9%A6-%E6%B5%B7%E5%A4%96%E5%8D%8E%E4%BA%BA%E4%B8%93%E7%94%A8%E7%BD%91%E7%BB%9C%E5%8A%A0%E9%80%9F%E4%BC%98%E5%8C%96/id6479953499"
                       target="_blank">
                        <svg t="1734080426494" className="icon" viewBox="0 0 1024 1024" version="1.1"
                             xmlns="http://www.w3.org/2000/svg" p-id="4239" width="38" height="38">
                            <path
                                d="M763.776 968.576H261.504C147.456 968.576 55.04 876.16 55.04 762.112V259.84c0-114.048 92.416-206.464 206.464-206.464h502.272C877.824 53.376 970.24 145.792 970.24 259.84v502.272c0 114.048-92.416 206.464-206.464 206.464z"
                                fill="#009CF5" p-id="4240"></path>
                            <path
                                d="M529.792 586.496h-100.48l75.648-130.56 7.68-13.312 51.84-89.088 9.984-17.408 21.504-36.864c9.472-16.128 9.728-36.864-1.024-52.096-9.216-13.056-22.4-18.304-35.456-18.304-15.36 0-30.336 7.936-38.784 22.272l-7.68 13.568-7.936-13.568c-8.448-14.336-23.552-22.272-38.784-22.272-7.68 0-15.36 1.792-22.528 5.888-21.248 12.288-28.544 39.808-16.128 61.056l33.408 57.728-135.168 233.216H238.08c-26.88 0.256-48.384 24.064-44.16 52.096 3.328 22.016 23.936 37.376 46.208 37.376h33.92l103.424-0.256H583.936c3.84 0 7.68-1.664 9.984-4.736 11.776-15.744 20.48-40.192-8.32-65.408-15.232-13.44-35.584-19.328-55.808-19.328z"
                                fill="#FFFFFF" p-id="4241"></path>
                            <path
                                d="M785.28 586.24h-86.016l-115.072-198.528c-2.048-3.456-6.656-4.608-9.856-2.304-16.128 11.648-25.6 27.904-30.464 45.824-8.192 30.208-1.408 62.592 14.208 89.728l24.704 42.88 13.056 22.784 51.84 89.344 5.376 8.96 49.792 86.016c8.448 14.336 23.296 22.272 38.528 22.272 7.68 0 15.616-2.048 22.784-6.144 21.248-12.288 28.544-39.552 16.128-61.056l-29.056-50.304h36.224c26.88 0 48.256-24.32 43.904-52.352-3.584-21.888-23.936-37.12-46.08-37.12zM319.616 687.616c-15.744-6.4-30.592-5.632-42.496-2.304-6.528 1.792-11.904 6.272-15.232 12.032l-16.64 28.416c-12.544 21.504-5.12 48.768 16.128 61.056 7.168 4.096 15.104 6.144 22.784 6.144 15.36 0 30.08-7.936 38.272-22.272l18.944-32.64c4.608-8.064 5.504-17.792 2.048-26.368-3.84-9.6-11.008-18.816-23.808-24.064z"
                                fill="#FFFFFF" p-id="4242"></path>
                        </svg>
                    </a>
                </div>
            </QueueAnim>
            { !props.isMobile && (
                <TweenOne animation={ { opacity: 1 } } className="banner-image-wrapper">
                    <img className="home-banner-anim" src={ bannerPng } alt="banner"/>
                </TweenOne>
            ) }
        </div>
    )
}

Banner.propTypes = {
    isMobile: PropTypes.bool.isRequired
}

export default Banner
